exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktywnie-nad-wisla-2023-tsx": () => import("./../../../src/pages/aktywnie-nad-wisla-2023.tsx" /* webpackChunkName: "component---src-pages-aktywnie-nad-wisla-2023-tsx" */),
  "component---src-pages-flota-boat-name-tsx": () => import("./../../../src/pages/flota/{Boat.name}.tsx" /* webpackChunkName: "component---src-pages-flota-boat-name-tsx" */),
  "component---src-pages-flota-tsx": () => import("./../../../src/pages/flota.tsx" /* webpackChunkName: "component---src-pages-flota-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lacza-nas-wiosla-2024-tsx": () => import("./../../../src/pages/lacza-nas-wiosla-2024.tsx" /* webpackChunkName: "component---src-pages-lacza-nas-wiosla-2024-tsx" */),
  "component---src-pages-nadwislanska-rownonoc-2022-tsx": () => import("./../../../src/pages/nadwislanska-rownonoc-2022.tsx" /* webpackChunkName: "component---src-pages-nadwislanska-rownonoc-2022-tsx" */),
  "component---src-pages-otwarcie-sezonu-2023-atrakcje-tsx": () => import("./../../../src/pages/otwarcie-sezonu-2023/atrakcje.tsx" /* webpackChunkName: "component---src-pages-otwarcie-sezonu-2023-atrakcje-tsx" */),
  "component---src-pages-otwarcie-sezonu-2023-bilety-rezerwacja-tsx": () => import("./../../../src/pages/otwarcie-sezonu-2023/bilety/rezerwacja.tsx" /* webpackChunkName: "component---src-pages-otwarcie-sezonu-2023-bilety-rezerwacja-tsx" */),
  "component---src-pages-otwarcie-sezonu-2023-bilety-tsx": () => import("./../../../src/pages/otwarcie-sezonu-2023/bilety.tsx" /* webpackChunkName: "component---src-pages-otwarcie-sezonu-2023-bilety-tsx" */),
  "component---src-pages-otwarcie-sezonu-2023-tsx": () => import("./../../../src/pages/otwarcie-sezonu-2023.tsx" /* webpackChunkName: "component---src-pages-otwarcie-sezonu-2023-tsx" */),
  "component---src-pages-podchody-wislane-2024-tsx": () => import("./../../../src/pages/podchody-wislane-2024.tsx" /* webpackChunkName: "component---src-pages-podchody-wislane-2024-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-regulaminy-bezplatne-tsx": () => import("./../../../src/pages/regulaminy/bezplatne.tsx" /* webpackChunkName: "component---src-pages-regulaminy-bezplatne-tsx" */),
  "component---src-pages-regulaminy-platne-tsx": () => import("./../../../src/pages/regulaminy/platne.tsx" /* webpackChunkName: "component---src-pages-regulaminy-platne-tsx" */),
  "component---src-pages-regulaminy-swieto-tsx": () => import("./../../../src/pages/regulaminy/swieto.tsx" /* webpackChunkName: "component---src-pages-regulaminy-swieto-tsx" */),
  "component---src-pages-regulaminy-tsx": () => import("./../../../src/pages/regulaminy.tsx" /* webpackChunkName: "component---src-pages-regulaminy-tsx" */),
  "component---src-pages-rejs-zapytanie-tsx": () => import("./../../../src/pages/rejs/zapytanie.tsx" /* webpackChunkName: "component---src-pages-rejs-zapytanie-tsx" */),
  "component---src-pages-rejsy-edukacyjno-przyrodnicze-2023-tsx": () => import("./../../../src/pages/rejsy-edukacyjno-przyrodnicze-2023.tsx" /* webpackChunkName: "component---src-pages-rejsy-edukacyjno-przyrodnicze-2023-tsx" */),
  "component---src-pages-rejsy-rownonoc-2023-tsx": () => import("./../../../src/pages/rejsy-rownonoc-2023.tsx" /* webpackChunkName: "component---src-pages-rejsy-rownonoc-2023-tsx" */),
  "component---src-pages-rejsy-tsx": () => import("./../../../src/pages/rejsy.tsx" /* webpackChunkName: "component---src-pages-rejsy-tsx" */),
  "component---src-pages-rejsy-world-cleanup-day-2023-tsx": () => import("./../../../src/pages/rejsy-world-cleanup-day-2023.tsx" /* webpackChunkName: "component---src-pages-rejsy-world-cleanup-day-2023-tsx" */),
  "component---src-pages-rezerwacja-tsx": () => import("./../../../src/pages/rezerwacja.tsx" /* webpackChunkName: "component---src-pages-rezerwacja-tsx" */),
  "component---src-pages-swieto-wisly-2023-bilety-tsx": () => import("./../../../src/pages/swieto-wisly-2023/bilety.tsx" /* webpackChunkName: "component---src-pages-swieto-wisly-2023-bilety-tsx" */),
  "component---src-pages-swieto-wisly-2023-tsx": () => import("./../../../src/pages/swieto-wisly-2023.tsx" /* webpackChunkName: "component---src-pages-swieto-wisly-2023-tsx" */),
  "component---src-pages-swieto-wisly-atrakcje-tsx": () => import("./../../../src/pages/swieto-wisly/atrakcje.tsx" /* webpackChunkName: "component---src-pages-swieto-wisly-atrakcje-tsx" */),
  "component---src-pages-swieto-wisly-bilety-rezerwacja-tsx": () => import("./../../../src/pages/swieto-wisly/bilety/rezerwacja.tsx" /* webpackChunkName: "component---src-pages-swieto-wisly-bilety-rezerwacja-tsx" */),
  "component---src-pages-swieto-wisly-bilety-tsx": () => import("./../../../src/pages/swieto-wisly/bilety.tsx" /* webpackChunkName: "component---src-pages-swieto-wisly-bilety-tsx" */),
  "component---src-pages-swieto-wisly-tsx": () => import("./../../../src/pages/swieto-wisly.tsx" /* webpackChunkName: "component---src-pages-swieto-wisly-tsx" */),
  "component---src-pages-szkoly-regulamin-tsx": () => import("./../../../src/pages/szkoly/regulamin.tsx" /* webpackChunkName: "component---src-pages-szkoly-regulamin-tsx" */),
  "component---src-pages-szkoly-rejestracja-tsx": () => import("./../../../src/pages/szkoly/rejestracja.tsx" /* webpackChunkName: "component---src-pages-szkoly-rejestracja-tsx" */)
}

