// @filename: models.ts
import { Models } from "@rematch/core"
import { booking } from "./models/booking"
import { toursCalendar } from "./models/tours-calendar"

export interface RootModel extends Models<RootModel> {
    booking: typeof booking
    toursCalendar: typeof toursCalendar
}

export const models: RootModel = { booking, toursCalendar }