import { DateTime, Duration } from 'luxon';
import { Boat } from './boat.model';

export interface TourPoint {
    name: string,
    pinpointUrl: string,
}

export interface Notice {
    title: string,
    message: string,
}
export interface Point {
    name,
    pinpointUrl
}

export interface tourJson {
    startDateTime: string
    id?: string,
    boat?: Boat,
    meetingPoint?: TourPoint,
    endPoint?: TourPoint,
    notice?: Notice,
    duration?: string,
}

export class Tour {
    id?: string;
    boat?: Boat;
    startDateTime?: DateTime;
    meetingPoint?: TourPoint;
    endPoint?: TourPoint;
    notice?: Notice;
    duration?: Duration;

    constructor(
        boat?: Boat,
        meetingPoint?: TourPoint,
        endPoint?: TourPoint,
        startDateTime?: DateTime,
        id?: string,
        notice?: Notice,
        duration?: Duration,
    ) {
        this.id = id;
        this.boat = boat;
        this.meetingPoint = meetingPoint;
        this.endPoint = endPoint;
        this.startDateTime = startDateTime;
        this.notice = notice;
        this.duration = duration;
    }

    static fromJson = function (json: tourJson): Tour {
        const startDateTime = DateTime.fromISO(json.startDateTime);
        const duration = json?.duration != null ? Duration.fromISO(json.duration) : null;
        return new Tour(json.boat, json.meetingPoint, json?.endPoint, startDateTime, json?.id, json?.notice, duration);
    };
}