// @filename: todo.ts
import { createModel } from "@rematch/core"
import { RootModel } from "../models"
import { DateTime } from 'luxon'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import axios from 'axios'
import i18next from "i18next"
import { TFunction } from "gatsby-plugin-react-i18next"

export interface PaymentMethod {
    id: string,
    name: string,
}

export interface BookingState {

    tourDateTime: DateTime,
    boatId: string,
    boatName: string,
    ticketsOrdered: number,

    ticketsLeft: number,
    depositPerTicket: number,
    ticketsPerOrder: number,
    paymentMethods: PaymentMethod[],
    translate?: TFunction,
    price?: number,
    projectId?: string,
}

const initalState = {

    tourDateTime: null,
    boatId: '',
    boatName: '',
    ticketsOrdered: 0,

    ticketsLeft: 0,
    depositPerTicket: 15,
    ticketsPerOrder: 6,
    paymentMethods: [],

    translate: null,

    price: null,
    projectId: null,

} as BookingState;

const ApiEndpoint = "https://wodnawarszawa.pl/api/";

const Api = axios.create();
Api.defaults.headers.post['Content-Type'] = 'application/json';

export const booking = createModel<RootModel>()({
    state: initalState,

    reducers: {
        INIT_FORM(state, payload: {
            boatId: string,
            boatName: string,
            tourDateTime: DateTime,
            ticketsLeft: number,
            translate: TFunction,
            price: number,
            projectId?: string,
            depositPerTicket?: number,
            ticketsPerOrder?: number,
        }) {
            state.boatId = payload.boatId;
            state.boatName = payload.boatName;
            state.tourDateTime = payload.tourDateTime;
            state.ticketsLeft = payload.ticketsLeft;
            state.translate = payload.translate;
            state.price = payload.price;
            state.projectId = payload.projectId;
            state.depositPerTicket = payload.depositPerTicket;
            state.ticketsPerOrder = payload.ticketsPerOrder;
        },
        SET_TICKETS(state, quantity: number) {
            state.ticketsOrdered = quantity;
        },
        SET_TICKETS_LEFT(state, quantity: number) {
            state.ticketsLeft = quantity;
        },
        SET_PAYMENT_METHODS(state, paymentMethods: PaymentMethod[]) {
            state.paymentMethods = paymentMethods;
        }
    },

    effects: (dispatch) => ({

        setTickets(quantity: number, rootState) {
            const quantityToSet = quantity <= rootState.booking.ticketsLeft ? quantity : 0;
            dispatch.booking.SET_TICKETS(quantityToSet);
        },

        setTicketsLeft(quantity: number, rootState) {
            dispatch.booking.SET_TICKETS_LEFT(quantity);
        },

        async initForm(payload: { boatId: string, boatName: string, tourDateTime: DateTime, ticketsLeft: number, translate: TFunction, price: number, projectId?: string, depositPerTicket?: number, ticketsPerOrder?: number, }, _) {
            dispatch.booking.INIT_FORM({ ...payload });
        },

        async fetchPaymentMethods() {

            // request list of method payments 
            const paymentSelectInput: (HTMLSelectElement | null) = (typeof document !== `undefined`) ? document.getElementById("paymentMethod") as HTMLSelectElement : null;

            const rawList = [{
                id: "102",
                name: "Bank Pekao SA"
            }, {
                id: "108",
                name: "PKO Bank Polski"
            }, {
                id: "110",
                name: "Inteligo"
            }, {
                id: "111",
                name: "ING Bank Śląski SA"
            }, {
                id: "113",
                name: "Alior Bank SA"
            }, {
                id: "114",
                name: "Bank Millennium SA"
            }, {
                id: "115",
                name: "Santander Bank Polska SA"
            }, {
                id: "116",
                name: "Credit Agricole Polska SA"
            }, {
                id: "119",
                name: "Getin Bank SA"
            }, {
                id: "124",
                name: "Bank Pocztowy SA"
            }, {
                id: "130",
                name: "Nest Bank"
            }, {
                id: "132",
                name: "Citibank Handlowy SA"
            }, {
                id: "133",
                name: "BNP Paribas Bank Polska SA"
            }, {
                id: "135",
                name: "Banki Spółdzielcze"
            }, {
                id: "145",
                name: "Plus Bank SA"
            }, {
                id: "150",
                name: "BLIK"
            }, {
                id: "159",
                name: "Neo Bank"
            }, {
                id: "160",
                name: "mBank"
            }];

            if (typeof document !== `undefined`)
                rawList.forEach((pm) => {
                    let newOption = document.createElement("option");
                    newOption.text = pm.name;
                    newOption.value = pm.id;
                    paymentSelectInput.add(newOption);
                })

            // Object.keys(rawList).forEach((key) => {
            //     paymentMethodsList.push({ id: rawList[key].id, name: rawList[key].name });
            // });
            // dispatch.booking.SET_PAYMENT_METHODS(paymentMethodsList);
        },

        async submitForm(payload: { formElement: HTMLFormElement, language?: string }, rootState) {
            const form = payload.formElement;
            const language = payload.language;

            if (form.checkValidity()) {

                form.classList.remove('was-validated');

                // retrieve data from form inputs
                const formInputs = form.getElementsByTagName('input');
                let formDataset = {};
                for (let i = 0; i < formInputs.length; i++) {
                    formDataset[formInputs[i].id] = formInputs[i].value;
                }

                // get rest of booking data from store
                const boatId = rootState.booking.boatId;
                const tourDateTime = rootState.booking.tourDateTime;
                const tourID = boatId + "." + tourDateTime.toFormat('ddLLyy') + "." + tourDateTime.toFormat('HHmm');
                const paymentMethod = form.getElementsByTagName('select')[0].value;

                const tourIsPayable = rootState.booking.price != null;

                const payload = {
                    variant: tourIsPayable ? "addBookingWithTransaction" : "addBookingWithDeposit",
                    fields: {
                        tourID: tourID,
                        tickets: formDataset['ticketsAmount'],
                        name: formDataset['fullName'],
                        phone: formDataset['phone'],
                        email: formDataset['mail'],
                        groupId: paymentMethod,
                        language: language ?? "pl",
                        projectId: rootState.booking.projectId,
                    }
                }
                Swal.fire(alerts.formSubmitInfo(rootState.booking.translate));

                const response = await axios.post(ApiEndpoint, payload);
                const jsonResponse = response.data;

                if (response.status == 200 && jsonResponse?.success) {
                    form.reset();
                    if (typeof window !== `undefined`) window.location = jsonResponse.paymentUrl;      
                } else {
                    if (response.status == 200) {
                        if (jsonResponse?.error && jsonResponse?.code == "TOUR_TICKETS_LIMIT_EXCEEDED" && typeof jsonResponse?.ticketsLeft !== `undefined`) {
                            if (jsonResponse?.ticketsLeft <= 0) {
                                Swal.fire(alerts.noTicketsLeft(rootState.booking.translate));
                                dispatch.booking.SET_TICKETS_LEFT(0);
                                dispatch.booking.SET_TICKETS(0);
                            } else {
                                Swal.fire(alerts.ticketsLimitExceeded(rootState.booking.translate, jsonResponse?.ticketsLeft))
                                dispatch.booking.SET_TICKETS_LEFT(jsonResponse?.ticketsLeft);
                                dispatch.booking.SET_TICKETS(jsonResponse?.ticketsLeft); 
                            }
                        }
                    } else {
                        Swal.fire(alerts.formSubmitError(rootState.booking.translate));
                    }
                }
            } else {
                Swal.fire(alerts.formHasErrors(rootState.booking.translate));
                form.classList.add('was-validated');

            }


        }
    }),
})

type SwalAlertConfig = {
    title: string,
    text: string,
    icon: 'error' | 'warning' | 'success' | 'info' | 'question',
    confirmButtonText?: string,
    allowOutsideClick: boolean,
    showConfirmButton?: boolean,

}

// i18next-extract-mark-ns-start booking_alerts
const alerts: { [key: string]: (t: TFunction, param?) => SwalAlertConfig } = {
    formSubmitInfo: (t) => {
        return {
            icon: 'info',
            title: t('Kieruję do płatności...'),
            text: t('Za chwilę nastapi przekierowanie do strony płatności. Prosimy czekać...'),
            allowOutsideClick: false,
            showConfirmButton: false,
        }
    },
    formSubmitError: (t) => {
        return {
            icon: 'error',
            title: t('Coś poszło nie tak...'),
            text: t('Wystąpił błąd podczas rezerwacji. Prosimy spróbować ponownie lub zgłosić błąd na kontakt@wodnawarszawa.pl'),
            allowOutsideClick: true,
        }
    },
    formHasErrors: (t) => {
        return {
            icon: 'warning',
            title: t('Błędy formularza'),
            text: t('Formularz zawiera błędy lub braki. Sprawdź i wyślij ponownie'),
            allowOutsideClick: true,
        }
    },
    checkingAvailability: (t) => {
        return {
            icon: 'info',
            title: t('Sprawdzam dostępność...'),
            text: t('Sprawdzam aktualną dostępność miejsc na wybraną atrakcję. Prosimy czekać...'),
            allowOutsideClick: false,
            showConfirmButton: false,
        }
    },
    noTicketsLeft: (t) => {
        return {
            icon: 'error',
            title: t('Brak wolnych miejsc'),
            text: t('Przykro nam, ale nie ma już wolnych miejsc na atrakcję. Zapraszamy na inne atrakcje!'),
            confirmButtonText: t('Zamknij'),
            allowOutsideClick: false
        }
    },
    ticketsLimitExceeded: (t, ticketsLeft) => {
        return {
            icon: 'error',
            title: t('Przekroczono ilość dostępnych miejsc'),
            text: t(`W momencie wysłania rezerwacji liczba dostępnych miejsc okazała się mniejsza niż ta którą chcesz zarezerwować. Zmniejszyliśmy ilość rezerwowanych miejsc w Twoim formularzu do ${ticketsLeft}.`),
            confirmButtonText: t('Zamknij'),
            allowOutsideClick: false
        }
    }
}