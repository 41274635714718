// i18next-extract-mark-ns-start tours_alerts
import { DateTime, Duration } from 'luxon';
import { createModel } from '@rematch/core'
import type { RootModel } from '../models';
import { ToursCalendarState, DayAvailableTours, UpcomingTourDates, Availability, Theme } from '../../models/tours-calendar.models';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import axios from 'axios';
import { Tour } from '../../models/tour.model';
import { Boat } from '../../models/boat.model';
import { TFunction } from 'gatsby-plugin-react-i18next';

const ApiEndpoint = "https://wodnawarszawa.pl/api/";

const Api = axios.create();
Api.defaults.headers.post['Content-Type'] = 'application/json';

const ApiRequests = {
    fetchUpcomingToursDates: async function (fromDate: DateTime, previewPass?: String, filterByProject?: string): Promise<UpcomingTourDates> {
        fromDate = fromDate.startOf("day");

        let payload = {
            variant: "getUpcomingToursDates",
            fields: {
                fromDay: fromDate.toISO(),
                filterByProject: filterByProject,
            }
        };
        if (previewPass) payload.fields['previewPass'] = previewPass;
        const response = await Api.post(ApiEndpoint, payload);
        const jsonResponse = response.data;

        return UpcomingTourDates.fromJson(jsonResponse);
    },
    getDayTours: async function (day: DateTime, endDay?: DateTime, previewPass?: String, filterByProject?: string): Promise<null | DayAvailableTours[]> {

        let payload = {
            variant: "getDayTours",
            fields: {
                filterByProject: filterByProject,
                day: day.toISO()
            }
        };
        if(endDay) payload.fields['endDay'] = endDay;
        if (previewPass) payload.fields['previewPass'] = previewPass;
        const response = await Api.post(ApiEndpoint, payload);
        if (response == null) return null;
        const jsonResponse = response.data;
        return jsonResponse.map((dayTours) => DayAvailableTours.fromJson(dayTours));
    }
}

const MyAlert = Swal.mixin({
    target: "#toursCalendarSection",
    allowOutsideClick: true,
    scrollbarPadding: false,
    heightAuto: false,
    position: 'top',
    customClass: {
        popup: 'mt-5',
        container: 'position-absolute',
        confirmButton: 'btn btn-secondary btn-lg',
        cancelButton: 'btn btn-danger btn-lg',
    },
    loaderHtml: '<div class="spinner-border text-primary"></div>',
})

export const toursCalendar = createModel<RootModel>()({

    name: 'toursCalendar',

    state: {
        daySelected: null,
        upcomingTourDates: null,
        dayAvailableTours: null,
        cachedDaysAvailableTours: [],
        translate: null,
        previewPass: null,
        project: null,
    } as ToursCalendarState,

    reducers: {
        SET_PROJECT(state, project: string) {
            state.project = project;
            return state;
        },
        SET_TRANSLATE(state, t: TFunction) {
            state.translate = t;
            return state;
        },

        SET_DAY_SELECTED(state, day: DateTime) {
            state.daySelected = day;
            return state;
        },
        SET_MONTH_AVAILABLE_TOUR_DATES: (state, dates: UpcomingTourDates) => {
            if (state.upcomingTourDates == null) {
                state.upcomingTourDates = dates;
            } else {
                state.upcomingTourDates.addDates(dates);
            }
            return state;
        },

        SET_DAY_AVAILABLE_TOURS: (state, tours: DayAvailableTours) => {
            state.dayAvailableTours = tours;
            return state;
        },
        ADD_CACHED_DAY_AVAILABLE_TOURS: (state, tours: DayAvailableTours[]) => {
            state.cachedDaysAvailableTours.push(...tours);
            return state;
        },
        SET_PREVIEW_PASS: (state, pass: String) => {
            state.previewPass = pass;
            return state;
        }
    },

    effects: dispatch => ({

        /**
         * Initalizes calendar
         * fetches upcoming dates from today
         * and selects fisrt tour available day
         */
        async initialize(payload: { translate: TFunction, initDate?: DateTime, filterByProject?: string, getAllUpcomingTours?: boolean, days?: number }, state,) {
            if (payload.filterByProject) dispatch.toursCalendar.SET_PROJECT(payload.filterByProject);
            if (state.toursCalendar.daySelected != null) return;

            dispatch.toursCalendar.SET_TRANSLATE(payload.translate);
            //TODO: create api endpoint for month tours dates
            try {

                const previewPass = await dispatch.toursCalendar.checkIfPreviewMode();

                MyAlert.fire({
                    text: payload.translate("Sprawdzam najbliższe terminy..."),
                    showConfirmButton: false,
                    timer: 30000,
                    icon: "info",
                    allowOutsideClick: false,
                });
                try {
                    if (payload?.getAllUpcomingTours) {
                        await dispatch.toursCalendar.getDayTours({ day: DateTime.now(), endDay: DateTime.now().plus({ days: payload?.days ? payload?.days : 14 }), previewPass: previewPass, filterByProject: payload.filterByProject })
                    } else {
                        const upcomingDates = await ApiRequests.fetchUpcomingToursDates(payload.initDate ?? DateTime.now(), previewPass, payload.filterByProject);
                        dispatch.toursCalendar.SET_MONTH_AVAILABLE_TOUR_DATES(upcomingDates);

                        const firstAvailableDay = upcomingDates?.upcomingToursDates?.length > 0 ? upcomingDates.upcomingToursDates[0] : null;
                        if (firstAvailableDay == null) throw Error("No upcoming dates available.");

                        dispatch.toursCalendar.SET_DAY_SELECTED(firstAvailableDay);
                        await dispatch.toursCalendar.getDayTours({ day: firstAvailableDay, previewPass: previewPass, filterByProject: payload.filterByProject });
                    }
                } catch (error) {
                    MyAlert.fire({
                        text: payload.translate("Brak dostępnych terminów. Spróbuj ponownie później."),
                        showConfirmButton: true,
                        timer: 15000,
                        icon: "warning",
                    });
                    return;
                }

                MyAlert.close();

            } catch (error) {
                MyAlert.fire({
                    text: payload.translate("Wystąpił błąd podczas pobierania terminów. Spróbuj ponownie później."),
                    showConfirmButton: true,
                    timer: 15000,
                    icon: "warning",
                });
                throw error;
            }
        },

        /**
         * Checks for preview mode and informs user about it
         */
        async checkIfPreviewMode() {
            let params = new URLSearchParams(document.location.search);
            let previewPass = params.get("previewPass");
            if (previewPass == null) return;

            dispatch.toursCalendar.SET_PREVIEW_PASS(previewPass);

            await Swal.fire({
                toast: true,
                icon: 'warning',
                position: 'top-end',
                showConfirmButton: true,
                timer: 5000,
                timerProgressBar: true,
                title: 'Tryb podglądu',
            });

            return previewPass;

        },

        /**
         * Fetches list of booking available dates
         * for tours calendar (this & next month)
         * 
         * @param fromDay a day to get upcoming tour dates from 
         */
        async getUpcomingToursDates(fromDay: DateTime, state) {
            try {
                MyAlert.fire({
                    text: state.toursCalendar.translate("Sprawdzam późniejsze terminy..."),
                    showConfirmButton: false,
                    timer: 30000,
                    icon: "info",
                    allowOutsideClick: false,
                });

                const upcomingDates = await ApiRequests.fetchUpcomingToursDates(fromDay, state.toursCalendar.previewPass);
                dispatch.toursCalendar.SET_MONTH_AVAILABLE_TOUR_DATES(upcomingDates);

                MyAlert.close();

            } catch (error) {
                MyAlert.fire({
                    text: state.toursCalendar.translate("Wystąpił błąd podczas pobierania terminów. Spróbuj ponownie później."),
                    showConfirmButton: true,
                    timer: 15000,
                    icon: "warning",
                });
                throw error;
            }
        },

        /**
         * Fetches tours for particular day 
         * with equipment availablity for each tour's hour
         * 
         * @param day a day for which to fetch available tours
         * @param _ 
         */
        async getDayTours(payload: { day: DateTime, endDay?: DateTime, previewPass?: String, filterByProject?: string }, state) {

            const availableTours = await ApiRequests.getDayTours(payload.day, payload?.endDay, payload.previewPass, payload.filterByProject);
            if (availableTours == null) throw Error();
            dispatch.toursCalendar.SET_DAY_AVAILABLE_TOURS(availableTours[0]);
            dispatch.toursCalendar.ADD_CACHED_DAY_AVAILABLE_TOURS(availableTours);

        },

        /**
         * Selects a day
         */
        async selectDay(day: DateTime, state) {
            if (day != state.toursCalendar.daySelected) {

                const cachedDayAvailableTours = state.toursCalendar.cachedDaysAvailableTours.find((dat) => dat.day.startOf("day").toISO() == (day).startOf("day").toISO()) ?? null;
                if (cachedDayAvailableTours != null) {
                    dispatch.toursCalendar.SET_DAY_AVAILABLE_TOURS(cachedDayAvailableTours);
                    dispatch.toursCalendar.SET_DAY_SELECTED(day);
                    return;
                }

                try {
                    MyAlert.fire({
                        text: state.toursCalendar.translate("Sprawdzam dostępność..."),
                        showConfirmButton: false,
                        timer: 30000,
                        icon: "info",
                        allowOutsideClick: false,
                    });

                    await dispatch.toursCalendar.getDayTours({ day: day, previewPass: state.toursCalendar.previewPass, filterByProject: state.toursCalendar.project });
                    dispatch.toursCalendar.SET_DAY_SELECTED(day);

                    MyAlert.close();
                } catch (error) {
                    MyAlert.fire({
                        text: state.toursCalendar.translate("Wystąpił błąd podczas pobierania listy. Spróbuj ponownie później."),
                        showConfirmButton: true,
                        timer: 15000,
                        icon: "warning",
                    });
                    throw error;
                }
            }
        },

        displayTourNotice({ title, message }: { title: string, message: string }, state) {
            Swal.fire({
                toast: true,
                title: title,
                text: message,
                showConfirmButton: false,
            })
        }
    }),
});