import { DateTime } from 'luxon';
import { Tour, tourJson } from './tour.model';
import { immerable } from "immer";
import { TFunction } from 'gatsby-plugin-react-i18next';

export interface ToursCalendarState {
    /**
     * Available dates for booking in tours calendar for particular days range 
     * (default range is from today unit last day of next month)
     */
    upcomingTourDates?: UpcomingTourDates,
    /**
     * Available tours for booking for particular day
     */
    dayAvailableTours?: DayAvailableTours,

    cachedDaysAvailableTours: DayAvailableTours[],

    daySelected?: DateTime,
    translate?: TFunction
    previewPass?: String,
    /**
     * Project id filter
     */
    project?:string
}

/**
 * Dates available for booking.
 * Used by calendar.
 */
export class UpcomingTourDates {
    [immerable] = true
    /**
     * Start of dates pagination range.
     */
    from: DateTime;
    /**
     * End of dates pagination range.
     * Used to fetch new dates batch for next months.
     */
    until: DateTime;
    /**
     * Tour dates available for booking
     */
    upcomingToursDates: DateTime[];

    constructor(from: DateTime, until: DateTime,
        upcomingToursDates: DateTime[]) {
        this.from = from;
        this.until = until;
        this.upcomingToursDates = upcomingToursDates;
    }

    static fromJson = function (json: { from: string, until: string, upcomingToursDates: string[] }): UpcomingTourDates {

        return new UpcomingTourDates(DateTime.fromISO(json.from), DateTime.fromISO(json.until), json.upcomingToursDates.map((date) => DateTime.fromISO(date)).sort());
    }

    addDates(utd: UpcomingTourDates): void {
        if (this.until > utd.until) return;
        this.until = utd.until;
        this.upcomingToursDates.push(...utd.upcomingToursDates);
    }

}

interface DayAvailableToursJson {
    day: string,
    projectId?: string;
    depositPerTicket?: number;
    ticketsPerOrder?: number;
    tours: TourWithAvailabilityJson[],
}

/**
 * Detailed list of tours with current availability for particular day.
 */
export class DayAvailableTours {

    /**
     * Day for which tours are fetched
     */
    day: DateTime;
    /**
     * Project id for API requests
     */
    projectId?: string;
    /**
    * Deposit value per ticket - from project settings
    */
    depositPerTicket?: number;
    /**
   * Tickets limit per order - from project settings
   */
    ticketsPerOrder?: number;
    tours: TourWithAvailability[];

    constructor(
        day: DateTime,
        tours: TourWithAvailability[],
        projectId?: string,
        depositPerTicket?: number,
        ticketsPerOrder?: number,
    ) {
        this.day = day;
        this.projectId = projectId;
        this.depositPerTicket = depositPerTicket;
        this.ticketsPerOrder = ticketsPerOrder;
        this.tours = tours;
    }

    static fromJson = function (json: DayAvailableToursJson): DayAvailableTours {
        const day = DateTime.fromISO(json.day);
        const tours = json.tours.map(tour => TourWithAvailability.fromJson(tour));
        return new DayAvailableTours(day, tours, json.projectId, json.depositPerTicket, json.ticketsPerOrder);
    }

}

interface TourWithAvailabilityJson {
    tour: tourJson,
    hoursAvailability: {
        hour: string,
        /**
         * Equipment total availability (pcs.)
         */
        availability: number,
        minTickets?: number,
        theme?: Theme,
        price?: number
    }[]
}

export interface Theme {
    title: string,
    message: string,
}

export class TourWithAvailability {

    tour: Tour;
    hoursAvailability: {
        hour: DateTime,
        /**
         * Equipment total availability (pcs.)
         */
        availability: Availability,
        /**
         * Minimum no of tickets that must be booked
         */
        minTickets?: number,
        /**
         * Tour theme
         */
        theme: Theme,
        /**
         * Tour's price
         */
        price?: number,

    }[];

    constructor(tour: Tour,
        hoursAvailability: {
            hour: DateTime,
            /**
             * Equipment total availability (pcs.)
             */
            availability: Availability,
            minTickets?: number,
            theme: Theme,
            price?: number,
        }[],) {
        this.tour = tour;
        this.hoursAvailability = hoursAvailability;
    }

    static fromJson = function (json: TourWithAvailabilityJson): TourWithAvailability {
        const hoursAvailability = json.hoursAvailability.map((ha) => { return { availability: new Availability(ha.availability), hour: DateTime.fromISO(ha.hour), minTickets: ha.minTickets, theme: ha.theme, price: ha.price } });
        return new TourWithAvailability(Tour.fromJson(json.tour), hoursAvailability);
    }

}

export class Availability {
    quantity: number;

    constructor(quantity) {
        this.quantity = quantity;
    }

    get level() {
        if (this.quantity <= 0) return 'none';
        if (this.quantity > 15) return 'major';
        if (this.quantity > 3) return 'good';
        if (this.quantity <= 3) return 'poor';
    }

}

